/**
 * Initialize the component 
 */
export default class ComponentDispatcher {
    /**
     * This method retrieve the component name from the html tag and try to istanciate the appropriate Component
     */
    dispatchToComponent() {
        let componentsToRender = [], tempComponents = [], componentsSplit = [], comma = '';
        let componentName = document.querySelectorAll('[data-component]');

        for (let [i, component] of componentName.entries()) {
            if (i != 0 ) { 
                comma = ',';
            }
            componentsSplit =  componentsSplit + comma + component.dataset.component.toString();

            tempComponents = componentsSplit.split(',');
        }
        
        componentsToRender = [...new Set(tempComponents)];

        for (let componentToRender of componentsToRender) {
            let componentName = `${componentToRender}`;

            import(/* webpackChunkName: `component-[request]` */ `../components/${componentName}`).then((module) =>{
                const DynamicComponent = module.default; // we except that the component is the the default attribute of the module
                const dynamicComponent = new DynamicComponent();
                dynamicComponent.render();
            }).catch(error => {
                console.warn("Failed to load the component", error.stack);
            });
        }
    }
}
