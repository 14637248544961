import {Loader} from '@googlemaps/js-api-loader';

export default class MapsComponent {
    constructor() {
        this.loader = null;
        this.librariesLoaded = false;
        this.loadLibrariesPromise = null;
    }

    static setGoogleKey(key) {
        let metaTag = document.querySelector('meta[name="google-api-key"]');

        if (!metaTag) {
            metaTag = document.createElement('meta');
            metaTag.name = 'google-api-key';
            document.head.appendChild(metaTag);
        }
        metaTag.setAttribute('content', key);
    }

    getGoogleKey() {
        const metaTag = document.querySelector('meta[name="google-api-key"]');
        return metaTag ? metaTag.getAttribute('content') : '';
    }

    async loadLibraries() {
        if (this.librariesLoaded) {
            return this.loadLibrariesPromise;
        }

        this.loadLibrariesPromise = new Promise(async (resolve, reject) => {
            try {
                this.loader = new Loader({
                    apiKey: this.getGoogleKey(),
                    version: 'weekly',
                });

                await Promise.all([
                    this.loader.importLibrary('core'),
                    this.loader.importLibrary('maps'),
                    this.loader.importLibrary('marker'),
                    this.loader.importLibrary('places'),
                    this.loader.importLibrary('geocoding'),
                ]);

                this.librariesLoaded = true;
                resolve();

            } catch (error) {
                console.error('Error loading Google Maps libraries: ', error);
                reject(error);
            }
        });

        return this.loadLibrariesPromise;
    }
}
