/**
 * Main entrypoint of the application.
 * Here is initialized the ControllerDispatcher and loaded the main resources shared by all pages
 */

import MapsComponent from "./utils/MapsComponent";

__webpack_public_path__ = `${document.querySelector("html").dataset.publicPath}/`;
const __svg__ = {
    path: '../svg/*.svg',
    name: 'css/sprite.[hash].svg',
};

const googleKey = "AIzaSyCCwqZXS5dzLRv2LFGk01pBgcIp-BqrQ9E";
MapsComponent.setGoogleKey(googleKey);

import 'what-input';
import '../sass/style.scss';
import svgxhr from 'webpack-svgstore-plugin-qc/src/helpers/svgxhr';
import ControllerDispatcher from './utils/ControllerDispatcher';
import ComponentDispatcher from './utils/ComponentDispatcher';

let ctrDispatcher = new ControllerDispatcher();
ctrDispatcher.dispatchToController();

let cmpDispatcher = new ComponentDispatcher();
cmpDispatcher.dispatchToComponent();

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

if (window.innerWidth > 767 && window.innerWidth < 1025) {
    window.addEventListener('orientationchange', (e) => {
        location.reload();
    });
}

svgxhr(__svg__);